import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "error"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.error.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString(_ctx.error), 1)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("nav", null, [
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => [
              _createTextVNode("Home")
            ]),
            _: 1
          }),
          _createTextVNode(" | "),
          _createVNode(_component_router_link, { to: "/about" }, {
            default: _withCtx(() => [
              _createTextVNode("About")
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_router_view)
      ]))
}